<template>
  <v-row class="fill-height">
    <v-col>
      <b-container fluid class="card p-4">
        <b-row>
          <b-col cols="6" class="col-header-calendar">
            <b-button class="mr-2 calendar-list-page__body__today-button" @click="setToday">Hôm nay
            </b-button>
            <v-btn class="mr-2" fab text small color="grey darken-2" @click="prev">
              <v-icon> mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn class="mr-2" fab text small color="grey darken-2" @click="next">
              <v-icon> mdi-chevron-right</v-icon>
            </v-btn>
            <h4 v-if="$refs.calendar" class="header-calendar-title m-1" style="display: inline">
              {{ $refs.calendar.title }}
            </h4>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <b-row> </b-row>

            <b-button class="btn btn-secondary ml-2" type="button" @click.stop="loadData">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/refresh-cw.svg" />
              </span>
              Làm mới
            </b-button>

            <b-button class="btn btn-secondary ml-2" type="button" @click.stop="chooseCourse"
              v-show="isWritePermission">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/search.svg" />
              </span>
              Chọn coach
            </b-button>

            <b-button :disabled="isLoading" class="btn btn-success ml-2" type="button" @click="handleEditAndSave"
              :variant="viewBool ? 'outline-success' : 'success'" v-show="this.coachId!='All'">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
              </span>
              {{ viewBool ? 'Lưu thay đổi' : 'Đặt lịch Coaching' }}
            </b-button>
            <b-modal id="confirmChangeModal" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc
              title="Xác nhận thay đổi">
              <p>Bạn có chắc chắn muốn lưu lại các thay đổi?</p>
              <div class="d-flex justify-content-end">
                <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('confirmChangeModal')">Quay
                  lại</b-button>
                <b-button class="btn btn-success ml-3" @click="handleSubmit" :disabled="isConfirm">Xác nhận</b-button>
              </div>
            </b-modal>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5">
            <b-row>
              <b-col cols="4">
                <h6 class="mb-2" v-if="
                  this.events.filter((event) => event.status === 0).length > 0
                ">
                  Tổng chỗ đặt: <strong>{{ this.events.length }}</strong>
                </h6>
                <h6 class="mb-2">
                  Số chỗ đã được đặt:
                  <strong>{{
                    this.events.filter((event) => event.status === 1).length
                  }}</strong>
                </h6>
              </b-col>
              <b-col cols="8">
                <h6 class="mb-2">
                  Health coach quản lý:
                  <strong>
                    {{
                      [
                        ...new Set(
                          this.events
                            .filter((event) => event.name)
                            .map((event) => event.name),
                        ),
                      ].length === 1
                        ? this.events.filter((event) => event.name)[0].name
                        : 'Admin'
                    }}
                  </strong>
                </h6>

                <h6 class="mb-2" v-if="
                  this.events.filter((event) => event.status === 0).length > 0
                ">
                  Cuộc hẹn tiếp theo:
                  <strong>
                    {{
                      this.events
                        .filter((event) => event.status === 1)
                        .sort(
                          (a, b) => new Date(a.start) - new Date(b.start),
                        )[0]?.start | moment('DD/MM/YYYY HH:mm')
                    }}
                  </strong>
                </h6>
              </b-col>

              <v-card v-for="(eventGroup, index) in [
                ...new Set(events.map((e) => e.name)),
              ]" :key="index" class="mb-1" v-show="events.find((e) => e.name === eventGroup).color !== '#01645A'
                  ">
                <v-card-text>
                  <h4 style="text-align: center;">
                    <strong>{{ eventGroup }}</strong>
                  </h4>
                  <ul>
                    <li v-for="(event, idx) in events.filter(
                      (e) => e.name === eventGroup && e.status === 1,
                    )" :key="idx" :style="{
                        backgroundColor: event.color,
                        color: getContrastYIQ(event.color),
                      }" class="event-item">
                      <p>{{ formatEventTimeSummary(event.start, event.end) }} </p>
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <v-sheet height="650">
        <v-calendar ref="calendar" v-model="value" :event-color="getEventColor" :event-ripple="false" :events="events"
          color="#c1d1ee" type="week" @change="getEvents" @mousedown:event="startDrag" @mousedown:time="startTime"
          @mouseleave="cancelDrag" @mousemove:time="mouseMove" @mouseup:time="endDrag" @click:event="showEvent">
          <template v-slot:event="{ event, timed, title }">
            <div class="v-event-draggable" :class="{ 'dense-event': isDenseDay(event.start) }">
              <div class="event-content">
                <div class="event-time">
                  {{ formatEventTime(event.start, event.end) }}


                </div>
                {{ event.calendarName }}
              </div>
            </div>
            <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"></div>
          </template>
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>

        </v-calendar>
        
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" :absolute="false"
          value="true" bottom left>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title>
                {{ formattedEventTime }}
                <div class="text-subtitle-2">{{ selectedEvent.name }}</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="removeEvent" v-show="selectedEvent.status == null || selectedEvent.status == 0
                ">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <div class="event-details">
                <div class="detail-item">
                  <strong>Status:</strong>
                  {{ selectedEvent.status === 0 ? 'Available' : 'Booked' }}<br>
                  <strong>Tên cuộc họp:</strong>
                  {{ selectedEvent.calendarName }}
                  <!-- <br><strong>Link người chủ trì:</strong>
                    {{ selectedEvent.meetingLink.slice(0, 50) + '...' }} -->


                </div>
              </div>
            </v-card-text>

            <v-card-actions>
              <b-button @click="selectedOpen = false">
                Đóng
              </b-button>
              <b-button class="btn btn-success ml-3" @click="openMeetingLink(selectedEvent.meetingLink)"
                v-show="selectedEvent.status == 1">
                Mở cuộc họp (host)
              </b-button>
              <b-button class="btn btn-success ml-3" @click="copyLink(selectedEvent.calendarLinkDynamic)"
                v-show="selectedEvent.status == 1">
                Copy link (khách hàng)
              </b-button>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
    <select-course-modal @selected-success="
      loadData();
    showCalendar = true;
    " :currentUser="this.currentUser" />
    <!-- <form-modal @load-data="loadData" /> -->
  </v-row>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import moment from 'moment-timezone';
const { mapState } = createNamespacedHelpers('calendarCoach');

export default {
  name: 'CoachesCalendar',
  components: {
    'select-course-modal': () => import('./components/SelectCourseModal.vue'),
    // 'form-modal': () => import('./components/FormModal.vue'),
  },
  data: () => ({
    value: '',
    events: [],
    names: [
      'Meeting',
      'Holiday',
      'PTO',
      'Travel',
      'Event',
      'Birthday',
      'Conference',
      'Party',
    ],
    courseSelected: null,
    coachSelected: null,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    ready: false,
    oldEvents: {},
    viewBool: false,
    count: 1,
    isLoading: false,
    valueprocess: 10,
    bufferValue: 20,
    isConfirm: false,
  }),
  methods: {
    copyLink(link) {
      if (link) {
        navigator.clipboard.writeText(link);
        this.$toastr.s({
          title: 'Sao chép thành công',
          msg: 'Đường dẫn tham gia zoom qua ứng dụng DIAB',
        });
      }
    },
    openMeetingLink(link) {
      window.open(link, '_blank');
    },
    getContrastYIQ(color) {
      const hex = color.replace(/^#/, '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114 + 500) / 1000;
      return yiq >= 128 ? 'black' : 'white';
    },
    isDenseDay(date) {
      // Count events on the same day
      const dayEvents = this.events.filter((event) => {
        const eventDate = new Date(event.start).setHours(0, 0, 0, 0);
        const checkDate = new Date(date).setHours(0, 0, 0, 0);
        return eventDate === checkDate;
      });
      return dayEvents.length > 3;
    },
    async handleEditAndSave() {
      this.viewBool = !this.viewBool;
      if (!this.viewBool) {
        this.$root.$emit('bv::show::modal', 'confirmChangeModal');
      }
    },
    importForm() {
      this.$bvModal.show('form-modal');
    },
    startDrag({ event, timed }) {
      if (!this.viewBool && this.event.status !== 1) {
        return;
      }
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      if (!this.viewBool && this.event.status !== 1) {
        return;
      }
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: this.coachName,
          color: '#01645A',
          start: this.createStart,
          end: this.roundTime(this.createStart + 30 * 60 * 1000),
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      if (!this.viewBool && this.event.status !== 1) {
        return;
      }
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        const startDate = new Date(newStart).getDate();
        const endDate = new Date(newEnd).getDate();
        if (startDate === endDate) {
          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
        }
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        const oldEndDate = new Date(this.createEvent.end).getDate();
        const maxDate = new Date(max).getDate();
        const minDate = new Date(min).getDate();

        if (oldEndDate === maxDate && minDate === maxDate) {
          this.createEvent.start = min;
          this.createEvent.end = max;
        }
      }
    },
    endDrag() {
      if (!this.viewBool && this.event.status !== 1) {
        return;
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (!this.viewBool) {
        return;
      }
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 30; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute,
      ).getTime();
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event.color;
    },
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`).getTime();
      const max = new Date(`${end.date}T23:59:59`).getTime();
      const days = (max - min) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const timed = this.rnd(0, 3) !== 0;
        const firstTimestamp = this.rnd(min, max);
        const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
        const start = firstTimestamp - (firstTimestamp % 900000);
        const end = start + secondTimestamp;

        events.push({
          name: this.rndElement(this.names),
          color: '#a0a5a938',
          start,
          end,
          timed,
        });
      }

      // this.events = events
    },
    setToday() {
      this.value = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    showEvent({ nativeEvent, event, day }) {
      if (day.past) {
        this.canRemove = false;
      } else {
        this.canRemove = true;
      }
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true)),
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    formatWeekday(date) {
      return date;
    },
    removeEvent() {
      this.events = this.events.filter((event) => event !== this.selectedEvent);
      this.selectedOpen = false; // Hide the menu with id "menuSlot"
    },
    chooseCourse() {
      this.$bvModal.show('coaches-calendar-modal');
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
      // TODO: scroll to the correct position
      const scrollTime = document.querySelector(`[data-time="${time}"]`);
      if (scrollTime) {
        const calendarContent = document.querySelector(
          '.v-calendar-daily__body',
        );
        calendarContent.scrollTop = scrollTime.offsetTop - 50;
      }
    },

    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    randomColors() {
      const colorCode = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += colorCode[Math.floor(Math.random() * 16)];
      }
      const invalidColors = ['#000000', '#FFFFFF', '#01645A', '#F44336'];
      if (invalidColors.includes(color)) {
        return this.randomColors();
      }

      return color;
    },
    async loadData() {
      this.coachId = this.currentUser?.id;
      this.isLoading = true;
      try {
        const payload = this.removeEmpty({
          CoachId: this.coachId,
          CourseId: this.course,
        });

        const params = new URLSearchParams();
        for (const property in payload) {
          params.append(property, payload[property]);
        }

        let endPoint = '';
        if (payload.CoachId == 'All') {
          endPoint = `CalendarCoach/AllCoachBooking`;
        } else {
          endPoint = 'CalendarCoach/Portal';
        }
        const { data } = await this.$api.get(endPoint, { params });

        const sevenHoursInMs = 7 * 60 * 60 * 1000;

        const coachColors = {};
        this.events =
          data?.map((el) => {
            const coachId = el.coachId;
            let color;
            if (!coachColors[coachId]) {
              coachColors[coachId] = this.randomColors();
            }
            color = coachColors[coachId];
            return {
              name: el.coachName,
              color: el.status === 0 ? el.coachColor : color,
              start: el.startTime * 1000 - sevenHoursInMs,
              end: el.endTime * 1000 - sevenHoursInMs,
              timed: true,
              status: el.status,
              courseName: el.courseName,
              courseId: el.courseId,
              calendarName: el.calendarName,
              meetingLink: el.meetingLink,
              calendarLinkDynamic: el.calendarLinkDynamic,
            };
          }) || [];
        this.$toastr.s({
          title: 'Cập nhập thành công',
          msg: 'Lịch đã được cập nhật',
        });
      } catch (error) {
        console.error('Calendar data fetch error:', error);
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message || 'Failed to load calendar data',
        });
      } finally {
        this.isLoading = false;
      }
    },
    formatEventTime(start, end) {
      return `${moment(start).format(
        'HH:mm',
      )} - ${moment(end).format('HH:mm')}`;
    },
    formatEventTimeSummary(start, end) {
      return `${moment(start).format('DD/MM/YYYY')} ${moment(start).format(
        'HH:mm',
      )} - ${moment(end).format('HH:mm')}`;
    },
    async handleSubmit() {
      this.$store.commit('context/setLoading', true);
      this.isConfirm = true;
      if (this.events.length === 0) {
        this.$toastr.e({
          title: 'Thất bại !',
          msg: 'Không được để trống lịch',
        });
        return;
      }
      const finalList = [];
      for (const event of this.events) {
        const coachId = localStorage.getItem('coachSelectedId');
        if (coachId === null) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: 'CoachId không được để trống',
          });
          return;
        }
        if (event.status !== 1) {
          finalList.push({
            CoachId: coachId,
            CourseId: this.course,
            Start: moment(event.start).format('MM/DD/YYYY HH:mm'),
            End: moment(event.end).format('MM/DD/YYYY HH:mm'),
            Status: 0,
          });
        }
      }
      const listEventsJson = JSON.stringify(finalList);

      try {
        this.isLoading = true;

        this.$store.commit('context/setLoading', true);
        if(this.coachId === 'All') {
          this.$toastr.w({
            title: 'Lưu ý!',
            msg: 'Hãy chọn một coach để đặt lịch',
          });
        }
        else
        var isUpdateSuccessful = await this.$api.post(
          'CalendarCoach/CreateMany',
          listEventsJson,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        if (isUpdateSuccessful) {
          this.$toastr.s({
            title: 'Thành công !',
            msg: 'Cập nhật lịch thành công',
          });
        } 
        this.$store.commit('context/setLoading', false);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Cập nhật lịch thất bại',
        });
      } finally {
        this.$emit('load-data');
        this.isLoading = false;
        this.isConfirm = false;

        this.$bvModal.hide('confirmChangeModal');
        this.$store.commit('context/setLoading', false);
      }
    },

    async viewAll() {
      // this.viewBool = !this.viewBool;
      // if(this.viewBool){
      //   this.oldEvents = this.events;
      // }

      try {
        this.$store.commit('context/setLoading', true);
        const { data } = await this.$api.get(
          'CalendarCoach/AllCoachBooking',
          {},
        );
        const sevenHoursInMs = 7 * 60 * 60 * 1000;

        this.events =
          data?.map((el) => {
            return {
              name: el.coachName,
              color: el.status === 0 ? el.coachColor : '#f44336',
              start: el.startTime * 1000 - sevenHoursInMs,
              end: el.endTime * 1000 - sevenHoursInMs,
              timed: true,
            };
          }) || [];
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
  },
  computed: {
    ...mapState(['coachId', 'course', 'coachName']),
    ...mapGetters(['currentUser']),
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
    formattedEventTime() {
      if (
        this.selectedEvent &&
        this.selectedEvent.start &&
        this.selectedEvent.end
      ) {
        const startTime = moment(this.selectedEvent.start).format('HH:mm');
        const endTime = moment(this.selectedEvent.end).format('HH:mm');
        return `${startTime} - ${endTime}`;
      } else {
        return '';
      }
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },
    formattedStartDate() {
      return this.course.time
        ? moment.unix(this.course.time).format('DD/MM/YYYY')
        : '';
    },
    isWritePermission() {
      console.log('this.$route.meta.isWritePermission', this.$route.meta);
      return this.$route.meta.isWriteAllPermission;
    },
  },
  mounted() {
    this.$refs.calendar.scrollToTime('07:00');
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  created() {
    localStorage.getItem('isAdminRole');
    this.isWritePermission();
  },
};
</script>

<style scoped lang="scss">
.v-menu {
  display: inline;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-summary {
  color: #ffffff;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.v-calendar .v-event-summary {
  white-space: pre-line;
}

.v-event-draggable {
  padding: 4px;
  height: 100%;

  .event-content {
    text-align: center;
    font-size: 9px;
    line-height: 1.1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .event-time {
    font-weight: bold;
  }

  .event-coach {
    font-weight: 500;
  }

  .event-status {
    font-size: 15px;
    padding: 1px 4px;
    border-radius: 4px;
    //  background-color: #4caf50;
    color: #000000;
    display: inline-block;

    &.status-booked {
      background-color: #f44336;
    }
  }

  .event-student,
  .event-course {
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Make sure event text is visible against the background
.v-event-timed {
  .v-event-draggable {
    color: rgb(0, 0, 0);
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  }
}
</style>
